.sites-name{
  color: #ffffff;
  letter-spacing: 0.3px;
}
.filter-header{
  color: #ffffff;
  letter-spacing: 0.3px;

}

#rapporter{
  margin-top: 25px;
}
#reports{
  
}
#radio-allOther{
  bottom: 20px;
}

.radio-container{
  
}

.radio-container fieldset{
  border: none;
  border-bottom: groove 1px;
  border-color: rgba(255,255,255,.40);
}

.checkbox-container{
  padding-left: 16px;
}

.filter-container h1{
  font-size: 16px;
  padding-left: 16px;

}


.sites-name input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* FILTER CHECKBOX */
.sites-name {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 16px;
  /* font-family: "Securitas Pro Light"; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sites-name input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #011523;
  border-style:solid;
  border-width: 1.5px;
  border-color: rgba(255,255,255,70);
  border-radius: 2px;
}

.sites-name:hover input ~ .checkmark {
  background-color: #011523;
}

.sites-name input:checked ~ .checkmark {
  background-color: #AE97FD;
  border-color: #AE97FD;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.sites-name input:checked ~ .checkmark:after {
  display: block;
}

.sites-name .checkmark:after {
  left: 3px;
  top: -1px;
  width: 5px;
  height: 10px;
  border: solid #031F30;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* FILTER RADIO */

/* The container */
.reports-name {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}


/* Hide the browser's default radio button */
.reports-name input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
}

/* Create a custom radio button */
.reports-name .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  /* height: 15px;
  width: 15px; */
  background-color: #011523;
  border-radius: 50%;
  border-width: 2px;
  border-color:gray;

}
.sites-name .checkmark {
  border-width: 2px;
  border-color:gray;
  height: 13px;
  width: 13px;
}


/* On mouse-over, add a grey background color */
/* .reports-name:hover input ~ .checkmark {
  background-color: #ccc;
} */

/* When the radio button is checked, add a blue background */
.reports-name input:checked ~ .checkmark {
  background-color: #011523;
  border-color: #AE97FD;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.reports-name .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.reports-name input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.reports-name .checkmark:after {
 	top: 2px;
	left: 2px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #AE97FD;
}
 /* RADIO END */

 .buttons-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  bottom: 0;
  height: 0px;
  background-color: #031F30;
  position:fixed;
  bottom:0;
  width:100%;
  z-index: 0;
  transition: height 0.7s;
 }

 .buttons-container-show{
  display: grid;
  grid-template-columns: 1fr 1fr;
  bottom: 0;
  height: 70px;
  background-color: #031F30;
  position:fixed;
  bottom:0;
  width:100%;
  z-index: 0;
  transition: height 0.7s;
 }

 .buttons-container button{
  margin-right: 16px;
  height: 50px;
  margin-top: 10px;
  border: solid;
  border-radius: 5px;
 }

 .buttons-container #clear{
  margin-left: 16px;
  border-color: #8D5FFF;
  border-width: 1px;
  background-color: #031F30;
  color: #8D5FFF;
  font-family: "Securitas Pro";
  font-size: 15px;
  letter-spacing: 0.46;
  font-weight: 600;
 }

 .buttons-container #filter{
  border-color: #8D5FFF;
  background-color: #8D5FFF;
  color: #ffffff;
  font-family: "Securitas Pro";
  font-size: 15px;
  letter-spacing: 0.46;
  font-weight: 500;
 }

 .filter-container{
  background-color: #011523;
  bottom: 0;
  width: 100%;
  height: 0%;
  display: block;
  z-index: 1;
  transition:all 2s;
  overflow: scroll;
  position:fixed;
  transition: height 0.7s;

  border-top: groove rgba(255,255,255,.30);


}

.filter-container-move{
  background-color: #011523;
  bottom: 0;
  width: 100%;
  height: 80%;
  border-radius: 2%;
  display: block;
  z-index: 1;
  transition:all 2s;
  overflow: scroll;
  position:fixed;
  transition: height 0.7s;
}

#sites{
  margin-bottom: 100px;
}

.header-close{
  height: 30px;
  margin: auto;
  width: 100%;
  z-index: 1;
  background-color:#011523;
  position:fixed;
  align-items: center;  
}

.close-img{
  width: 30px;
  padding: 10px;
  background-color: #011523;
  z-index: 1;
  position:fixed;
  left: 87%;

}

.horizontal-divider{
  background-color: rgba(255,255,255,.60);
  height: 4px;
  margin: auto;
  width: 32%;
  margin-top: 12px;
  border-radius: 5px;
}