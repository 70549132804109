.header-page-filter{
  display: grid;
  padding:0 16px;
  gap:6px;
  width:100%;
  top:0;
  background:#031F30;
  position:fixed;
  grid-template-columns: 1fr 100px;
  z-index: 1;

}
.header-filter{
  float: left;
}

.badge{
}

.header-page-filter button{
  margin-right: 20px;
  background-color: #031F30;
  border-style: none;
}
.filter-header h2{
  margin-left: 16px;
  margin-right: 16px;
  font-size: 14px;
  font-weight:100;
  font-family: "Securitas Pro Light";
  letter-spacing: 0.5px;
}

.description-container p {
    color:#031F30;
    font-weight:400;
    font-size:14px;
    line-height:21px;
    }
    
    .bell-img {
    flex:-1;
    height:15px;
    margin:5px 8px 10px 0 !important
    }
    
    .menu-reports{
      margin-top: 2.5rem;
      margin-bottom: 3rem;
    }
    .menu-reports ul li {
      margin-bottom: -25px;
    }
    ul{
      list-style-type: none;

    }
    
    #menu-reports a {
    padding:0!important
    }
    
    .report-element .report-time p {
    float:right;
    text-decoration-line: none;
    }
    .report-element{
      text-decoration: none !important;

    }
  
    .alarm-type-callout {
    display:grid;
    grid-template-columns:25px 10fr 4fr
    }
    
    .alarm-type-beat {
    display:grid;
    grid-template-columns:10fr 4fr
    }
    
    .alarm-type-beat p, .alarm-type-callout p{
      font-family: "Securitas Pro" !important;
      color: rgba(0, 0, 0, 0.6);
      font-size: 12px;
      
    }

    .alarm-type-beat h4, .alarm-type-callout h4{
      width: 80%;
    }

    .report-time {
    font-weight:400;
    font-size:12px;
    line-height:18px;
    color: #ffffff;
    text-decoration: none;
    float: right;
    margin-top: 3px;


    }
    #time p{
      text-decoration: none !important;

    }
    #time{
      float: right;
      margin-right: 2px;
      opacity: 0.7;

    }
    
    .section-date .date {
    margin-left:-24px;
    margin-bottom:-20px;
    }
    
    .section-date-divider {
    border-top:groove;
    border-width:thin;
    border-color:#F7F7F7;
    opacity: 20%;
    margin-bottom:15px;
    margin-left:-40px;
    margin-top:0
    }
    
    .type-container {
    margin-left:-23px;
    margin-right:16px;
    margin-top:-20px
    }
    
    .arrow-img {
    margin-right:16px
    }
  
    .category-img-container{
      display: grid;
      margin-top: -15px;
      grid-template-columns: 1fr 55px;
    }
    .category-container p {
      font-size: 12px;
      color: #7599BF;
      margin-top: 4px;
      margin-bottom: 3px;  
      text-decoration: none;
    }
    .category-img-container{
      margin-bottom: 10px;
    }
    .img-icon{
      float:right;
      filter: invert(100%) sepia(0%) saturate(7488%) hue-rotate(127deg) brightness(117%) contrast(101%);
  
    }
  
    .img-icon-comment{
      float:right;
      filter: invert(100%) sepia(0%) saturate(7488%) hue-rotate(127deg) brightness(117%) contrast(101%);
  
    }
    .report-desc-container{
      padding-bottom: 0;
      margin-top: -5px;
      width: 90%;

    }
    .icon-container{
      margin-top: 3px;
    }
    .report-desc-container p{
      margin-top: 16px;
      font-family: "Securitas Pro" !important;
      font-size: 13px;
      margin-bottom: 1px;
    }
    .alarm-text h4{
      margin-bottom: 0;
    }

    .alarm-text-beat h4{
      margin-bottom: 10px;
      width: 90%;
    }
    .alarm-text h4{
      width: 90%;
    }
    .menu-reports h5{
      margin-top: 7px;
    }
    /* Loader */
    .loader-container{
      display: block;
      margin: auto;
      width: 17%;
      background: transparent;
      overflow: hidden;
    }
    .lds-ring {
      position: fixed;
      top: 45%;
      
    }
    .lds-ring div {
      display: block;
      position: fixed;
      width: 35px;
      height: 35px;
      margin: 8px;
      border: 5px solid #fff;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #fff transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }
    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
.header-page-filter img{
  margin-top: 16px;
}

.no-reports{
  margin-top: 90px;
  margin-left: 16px;
}
.no-reports h3{
  font-size: 14px;
  letter-spacing: 1px;
}


.filterIcon{
  display: none;
  width: 0%;
  position: absolute;
  bottom: 30px;
}
.scroll-container{

  opacity: 1;
  transition: opacity 1s;
  clear:both;

}
.scroll-container-not-active{

  opacity: 0.1;
  pointer-events: none;
  background-color: black;
  transition: 1s;
}
.noreports-img{
  width: 35%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.infinite-scroll-component{
  margin-top: 2.1rem;
  margin-bottom: 3rem;


}

.report-name h3{
  padding-top: 20px !important;
  color: #7599BF;
  width:75%
}
.bottom-loader h3{
  color: #F7F7F7;
}
li::marker{
  display: none;
}