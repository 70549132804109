/* Main */

@font-face {
  font-family:'Securitas Pro';
  src:local('Securitas Pro'),url(./fonts/SecuritasPro-Regular.woff) format("woff")
  }
  
  @font-face {
  font-family:'Securitas Pro Light';
  src:local('Securitas Pro Light'),url(./fonts/SecuritasPro-Light.woff) format("woff")
  }
  
  body {
  font-family:'Securitas Pro';
  background-color:#011523;
  orientation: portrait;
  }
  
  .header-page {
  display:inline-block;
  padding:0 16px;
  gap:6px;
  width:100%;
  top:0;
  background:#031F30;
  position:fixed;
  }
  
  h1 {
  font-style:normal;
  font-family:'Securitas Pro Light';
  font-weight:600;
  font-size:20px;
  line-height:160%;
  display:flex;
  align-items:center;
  letter-spacing:.15px;
  color:#FFF;
  
  }
  
  h3 {
  font-style:normal;
  font-weight:700;
  font-size:11px;
  line-height:16px;
  margin-bottom:0;
  font-family:'Securitas Pro Light';
  width:80%;
  display:flex;
  align-items:center;
  letter-spacing:1px;
  text-transform:uppercase;
  max-width:300px;
  color: #F6F6F6;
  
  }
  
  h4 {
  font-style:normal;
  font-weight:700;
  font-size:14px;
  line-height:21px;
  font-family:'Securitas Pro';
  line-height:16px;
  margin-top:5px;
  color: #F6F6F6;
  letter-spacing: 0.6px;
  

  }
  
  h5 {
  font-family:'Securitas Pro Light';
  font-style:normal;
  font-weight:700;
  font-size:14px;
  line-height:157%;
  color: #F6F6F6;
  opacity: 70%;
}
h6{
  font-style:normal;
  font-family:'Securitas Pro Light';
  font-weight:600;
  font-size:15px;
  display:flex;
  justify-content: right;
  letter-spacing:.15px;
  color: #9977FE;
  margin-top:20px;
  margin-bottom:16.4px;

}

  
  p {
  font-style:normal;
  font-family:'Securitas Pro';
  font-size:14px;
  color: #F6F6F6;
  font-weight:500;

  }


