.main-container{
    margin-left: 16px;
    margin-top: 75px;

  }
  .main-container h2{
    font-family: 'Securitas Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 160%;
    color: #FFFFFF;
    margin-right: 16px;
  }

  
  .date-time-container{
    color: #FFFFFF;
    display: grid;
    grid-template-columns:fit-content(1ch) fit-content(1ch) 30fr;
    margin-top: -12px;
  }
  .element-dot{
    margin-left: 5px;
    margin-right: 5px;
  }
  .date-time-container p {
    color: #FFFFFF;
    font-family: "Securitas Pro";
  }
  .event-details-container{
    margin-top: 12px;
  }
  .event-details-container h4{
    font-size: 14px;
  }

  .detail-section{
    display: grid;
    grid-template-columns:fit-content(1ch) 1fr fit-content(1ch);
    
  }
  .object-type p {
    font-size: 11px;
  }
  
  .detail-section p{
    color: #FFFFFF;
    font-size: 11px;
    margin-top: -8px;
    white-space: nowrap;
    
  }
  .event-object-desc, .event-object-address{
    justify-self: end;
    margin-right: 16px;
    display: inline-block;
    white-space: nowrap;
    
  }
  .object-type{
    justify-self: start;
    
  }
  .object-underline{
    margin-top: 3px;
    border-top:dotted;
    border-width:thin;
    border-color: #FFFFFF;
    opacity: 20%;
    z-index: -1;
  }
  .category {
    border-color: #ACC2DD;
    display: inline-block;
    border-style: solid;
    border-width:thin;
    border-radius: 25px;
    margin-right: 6px;
    margin-bottom: 6px;
  }
  .category p {
    margin: 4px 8px 4px 8px;
    font-size: 13px;
    color: #ACC2DD;    ;
  }
  .categories-container h4{
    color: #FFFFFF;
  }
  .report-details-images p{
    color: #FFFFFF;
    font-size: 12px;

  }
  .report-details-comment p{
    color: #FFFFFF;
    font-size: 12px;
    margin-top: 10px;

  }

  .menu-reports{
    display:inline-block;
    flex-direction:column;
    display:inline;
    position:absolute;
    z-index:-1;
    width:100%  
  }

  .menu-reports ul li a {
    text-decoration:none;
    text-align:left;
    padding:16px;
    margin-left:-40px;
    align-items:center;
    color:   #F7F7F7;
  
    }
    
  .menu-reports ul {
    list-style-type:none;
    padding-bottom: 45px;
  }
  
  .comment{
    font-size: 14px;
    color: #FFFFFF;
    margin-bottom: 1em;

  }
  .report-details-comment{
    margin-right: 16px;
  }
  .callout-header{
    display:grid;
    grid-template-columns:1fr 10fr;
    margin-top: -15px;
    margin-bottom: 5px;
  }
  .bell-callout-img{
    align-self: center;
  }

  .detail-section p #building-section{
    word-wrap: break-word;

  }

  .scroll-img{
    width: 256px;
    height: 256px;
    margin-right: 8px;
    border-radius: 5%;
  
  }
  .images-section{
    flex:none;
    overflow-x: scroll;
    display: flex;
    margin-right: 16px;
    margin-bottom: 16px;
  }