.menu {
    display:inline-block;
    flex-direction:column;
    display:inline;
    position:absolute;
    top:45px;
    z-index:-1;
    width: 100%;

    }

    .menu ul{
      padding-left: 0px !important;
    }
    .menu li{
      display: grid;
      grid-template-columns: 55px 1fr;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    
    .menu ul li a {
    text-decoration:none;
    text-align:left;
    align-items:center;
    color:   #F7F7F7;
  
    }
    
    .menu ul {
    list-style-type:none;
    }
    
    .menu-img {
      filter: invert(100%) sepia(0%) saturate(7488%) hue-rotate(127deg) brightness(117%) contrast(101%);
      opacity: 56%;
      width: 24px;
      margin-top: 20%;
      padding-left: 16px;
    }

    .button-logout{
      text-decoration: none;

    }

    .item-menu p {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.7);    
      margin: 5px 0 !important;
      text-decoration: none !important;
    }
    .menu-divider{
      border-top:groove;
      border-width:thin;
      border-color:#F7F7F7;
      opacity: 20%;
    }
    .logout-container{
      display: grid;
      grid-template-columns: 50px 1fr;
      background:rgba(255, 255, 255, 0.01);
      position:fixed;
      bottom:0;
      width:100%;
      height: 60px;
      padding-top:5px;
      padding-bottom:5px;
      margin-bottom: 163px;
      border-top: groove rgb(255, 255, 255, 0.2);
      border-bottom: groove rgb(255, 255, 255, 0.2);
      border-width:thin;
      


    }
    .copyright{
      position:fixed;
      bottom:0;
      margin-bottom: 40px;
      height: 104px;
    }
    .copyright p{
      margin: 0px 16px 0px 16px;
      font-size: 12px;
      margin-top: 10px;
    }

    .img-logout{
      filter: invert(100%) sepia(0%) saturate(7488%) hue-rotate(127deg) brightness(117%) contrast(101%);
      opacity: 56%;
      width: 24px;
      margin-top: 18px;
      padding-left: 16px;
    }
    .logout-container p{
      font-size: 16px;
      color: #FFFFFF;    
      margin: 18px 0 !important;
      text-decoration: none !important;
    }

    .img-securitas{
      width: 13%;
      margin-left: 16px;
    }

    @media (min-width: 600px) and (orientation:landscape) {
      .copyright{
        margin: 0px 16px 0px 16px;
        font-size: 12px;
        margin-top: 10px;
        display:block;
        position: absolute;
        top:320px;
        padding-bottom: 40px;


      }
      .logout-container{
        display:grid;
        grid-template-columns: 55px 1fr;
        background:rgba(255, 255, 255, 0.01);
        position: absolute;
        top:220px;
        padding-top:0px;
        padding-bottom: 0px;
        border-width:thin;
      }

      .img-securitas{
        width: 11%;
      }
    }

    .button-logout {
      text-decoration: none;
    }

