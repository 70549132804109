.menu-chat{
    padding:0!important;
}

.header-page-chat{
    display:inline-block;
    gap:6px;
    width:100%;
    top:0;
    background:#031F30;
    position:relative;
}
.header-page-chat p {
    font-family:'Securitas Pro';
    padding: 0 16px;

}
.header-page-chat h1{
    padding: 8px 0 0 16px;
}
.header-page-chat p {
    margin-top: -10px;
    font-size: 14px;
    font-weight: 700;
}
.header-page p {
    margin-top: 0px !important;

}


#construction-text{
   opacity: 75%; 
   font-size: 18px;
   text-align: center;
}

.construction-img{
    width: 40%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top:27%;
    
}
.chatContainer{
    position: relative;
    display:block;
    top:10px;
    overflow: hidden;

    /* top:160px; */

}
div.chatList{
    height: calc(83vh - 130px);
    position: relative;
    overflow-y: scroll;
    margin-bottom:15px;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;

}
@media only screen and (max-width: 720px) {
    div.chatList{
        height: calc(80vh - 130px);
        position: relative;
        overflow-y: scroll;
        margin-bottom:15px
    
    }
  }

.block-container{
    overflow: hidden;
}
.chat-banner h5{
    font-size: 12px !important;
    margin-bottom: 10px;
    color: #ACC2DD;
    font-weight:500;
    font-size:12px !important;
    line-height:120%;
    margin-top: 10px;
}

.chat-banner{
    padding:0 16px;
    margin: 0 !important;
    display: grid;
    grid-template-columns: 30px 1fr;
    background-color: #0E283D;

}
.warning-img{
    margin-top:35px;
    width:21px
}
.header-page p {
    font-weight:600;

}


  #message::placeholder{
    opacity: 0.5;
    color: #F6F6F6;
  }

  .message-their {
    position: inherit;
    float: left;
    margin-left: 8px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #0E283D;
    width: 75%;
    text-align: left;
    font: 400 .9em 'Open Sans', sans-serif;
    border: 1px solid #0E283D;
    border-radius: 0px 10px 10px 10px;
}

.message-my {
    position: inherit;
    float: right;
    margin-right: 8px;
    margin-bottom: 10px;
    margin-left: calc(100% - 240px);
    padding: 10px;
    background-color: #233C59;
    width: 75%;
    text-align: left;
    font: 400 .9em 'Open Sans', sans-serif;
    border: 1px solid #233C59;
    border-radius: 10px 0px 10px 10px;
}

.message-timestamp{
    margin: auto;
    width: 50%;
    margin-bottom: 10px;
    align-items: center;

}

.message-timestamp h3{
    margin: auto;
    width: 40%;
    text-transform:capitalize;
    letter-spacing:0.1px;
    align-self: center;

    }

.message-content{
    font-size: 13px !important;
    color: #F6F6F6;
    line-height: 1.5 !important;
    font-weight: thin;
    font-family:'Securitas Pro';
}

.message-content {
    padding: 0;
    margin: 0;
    word-wrap: break-word;

}

.message-username-right {
    position: absolute;
    font-size: .85em;
    font-weight: 300;
    right: 5px;
}

.message-username-left h4{
    font-size: 13px;
}

.textInput-container{
    position: fixed;
    bottom: 50px;
    padding: 0px 8px 0px 8px;
    background-color: #031F30;
    color: #F6F6F6 !important;
    display: grid;
    grid-template-columns: 20fr 1fr;
}
#message {
    width: 100%;
    padding: 6px 12px;
    margin: 8px 0;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #233C59;
    border: solid #233C59 1px;
    color:#F6F6F6;
    overflow-y: auto;

    
  }
.send-message-btn{
    width: 100%;
    height: 28px;
    margin-top: 9px;
    background-color: #9977FE;
    border: solid #9977FE;
    border-radius: 5px;
    float: right;

    
}
.send-message-btn img{
    height: 20px;
}

.send-message-btn-active{
    width: 100%;
    height: 30px;
    margin-top: 8px;
    background-color: #9977FE;
    border: solid #9977FE;
    border-radius: 5px;
    float: right;
}

.send-btn-container{
    width: 80%;
    padding-left: 8px;

}


@media (min-width: 600px) and (orientation:landscape) {
    .construction-img{
        width: 80px;
    }
}
