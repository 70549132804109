.navbar {
    background:#031F30;
    position:fixed;
    bottom:0;
    width:100%;
    padding-top:5px;
    padding-bottom:5px;
    color:rgba(0,0,0,0.9);
    z-index:0
    
    }
    
    .navbar a {
    font-style:normal;
    text-decoration:none !important;
    }
    
    .navbar ul {
    list-style:none;
    margin:0;
    padding:0
    }
    
    .navbar ul li {
    float:left;
    width:33%;
    font-size:14px;
    letter-spacing:.4px;
    text-align:center;
    text-decoration:none !important;
  
  
    }
    
    .navbar img {
    display:block;
    height:24px;
    margin-left:auto;
    margin-right:auto;  
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(136deg) brightness(98%) contrast(105%);
    }
    
    .navbar li.active img {
    filter: brightness(0) saturate(100%);
    filter: invert(68%) sepia(49%) saturate(6962%) hue-rotate(224deg) brightness(101%) contrast(99%);
    }
    
    .navbar li a{
      color:#FFFFFF;
  
    }
    .navbar li.active a {
      color:#9977FE;
  
    }